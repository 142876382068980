
import Vue from "vue";
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from "dayjs";
import { required, requiredIf, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

const defaultData = {
    id: "",
    firstName: "",
    lastName: "",
    fullName: "",
    email: "",
    alternate_email: "",
    phoneNumber: "",
    position: "",
    otherPosition: "",
    roles: [],
    dateOfAppointment: "",
    gender: "",
};

export default Vue.extend({
    name: "CreateUserModal",
    props: {
        edit: { type: Boolean, required: false, default: false },
        user: { type: Object, required: false, default: () => ({}) },
        userType: { type: String, default: "User" },
        defaultPosition: { type: String, default: "" },
        modalId: { type: String, required: true },
        preferredPositions: { type: Array, default: () => [] },
        hasPreferredPositions: { type: Boolean, default: false },
        showRoles: { type: Boolean, default: true },
    },
    watch: {
        edit(val) {
            if (val) {
                this.setForm(this.user);
            } else {
                this.form = { ...defaultData, position: this.defaultPosition };
            }
        },
        user(val) {
            if(val) {
                this.setForm(val);
            }
        },
    },
    computed: {
        ...mapGetters({
            positions: "company/companyPositionsText",
        }),
        updateText(): string {
            return this.edit ? "Update" : "Create";
        },
        updateDescription(): string {
            return this.edit ? "Update a" : "Add a new";
        },
        positionOptions() {
            return this.hasPreferredPositions ? [...this.preferredPositions, "Others"] : [...this.positions, "Others"];
        },
    },
    data() {
        return {
            loading: false,
            isEdit: false,
            form: {
                ...defaultData,
                position: this.defaultPosition,
            },
            genders: [
                { id: 0, text: "Not Specified" },
                { id: 1, text: "Male" },
                { id: 2, text: "Female" },
            ],
            rolesArray: [
                { id: "ReportCreator", text: "Report Creator" },
                { id: "ReportReviewer", text: "Report Reviewer" },
                { id: "ReportSignatory", text: "Report Signatory" },
                { id: "Admin", text: "Admin" },
            ],
        };
    },
    methods: {
        ...mapActions({
            saveUser: "users/createUser",
            fetchUserProfile: "users/fetchSingleUser",
            updateUser: "users/updateSingleUser",
            updateUserRoles: "users/updateSingleUserRoles",
        }),
        closeModal() {
            this.resetData();
            this.$bvModal.hide(`modal-${this.modalId}`);
        },
        resetData() {
            this.isEdit = false;
            this.$emit("resetEdit");
            console.log('This was called')
            this.form = {
                ...defaultData,
                position: this.defaultPosition,
            };
        },
        inPositions(position: string) {
            return !this.hasPreferredPositions ? this.positions.includes(position) : this.preferredPositions.includes(position);
        },
        setForm(payload: any) {
            const fullName = payload.fullName.replace(/\s+/g, " ").trim();
            const [firstName, lastName] = fullName.split("");
            const dateOfAppointment = dayjs(payload.dateOfAppointment).format("YYYY-MM-DD") as any;
            this.form = {
                id: payload.id,
                fullName,
                alternate_email: payload?.alternate_email ?? "",
                firstName,
                lastName,
                email: payload.email,
                phoneNumber: payload.phoneNumber,
                position: this.inPositions(payload.position) ? payload.position : "Others",
                otherPosition: payload.position,
                roles: (this.rolesArray.filter((role) => payload?.roleIds?.includes(role.id)) || []) as any,
                gender: payload.gender,
                dateOfAppointment,
            };
        },

        async createUser() {
            this.loading = true;
            const {
                position,
                otherPosition,
                roles,
                // dateOfAppointment,
                ...payload
            } = this.form;
            const roleBody = roles.map((item: any) => item.id);
            const splitNames = payload.fullName.split(" ");
            const body = {
                ...payload,
                roleIds: roleBody,
                position: position === "Others" ? otherPosition : position,
                firstName: this.edit ? splitNames[0] : this.form.firstName,
                lastName: this.edit ? splitNames.slice(1).join(" ") : this.form.lastName,
                // dateCreated: dateOfAppointment || null,
            };
            try {
                let isCompleted = false;
                if (this.edit || this.isEdit) {
                    const response = await this.updateUser({
                        userId: this.isEdit ? this.form.id : this.user.id,
                        body,
                    });
                    isCompleted = !!response.message;
                    await this.updateUserRoles({
                        userId: this.isEdit ? this.form.id : this.user.id,
                        body: roleBody,
                    });
                } else {
                    const response = await this.saveUser({ ...body });
                    isCompleted = !!response.message;
                }
                if (isCompleted) {
                    this.$bvToast.toast("Success", {
                        title: `User ${this.isEdit ? "update" : this.updateText}d successfully!`,
                        variant: "success",
                        solid: true,
                    });
                    this.$emit("completed");
                    this.resetData();
                    this.$v.$reset();
                }
            } catch (e) {
                this.$bvToast.toast("Error", {
                    title: `Unable to ${this.updateText} User!`,
                    variant: "danger",
                    solid: true,
                });
            } finally {
                this.loading = false;
            }
        },
    },
    validations(): any {
        return {
            form: {
                firstName: { required },
                lastName: { required },
                fullName: {
                    required: requiredIf(() => this.edit === true),
                    minLength: minLength(6),
                },
                alternate_email: {
                    required: requiredIf(() => false),
                },
                email: { required },
                phoneNumber: { required },
                position: { required },
                dateOfAppointment: { required },
                gender: { required },
                otherPosition: {
                    required: requiredIf(({ position }) => position === "Others"),
                },
            },
        };
    },
});
