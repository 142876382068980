var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "id": _vm.modalId,
      "title": `${_vm.isEdit ? 'Update' : _vm.updateText} ${_vm.userType}`,
      "description": `${_vm.isEdit ? 'Update' : _vm.updateDescription} ${_vm.userType} profile`,
      "apply-body-classes": false,
      "has-footer": ""
    },
    on: {
      "hide": _vm.resetData
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('ba-button', {
          staticClass: "text-gray-dark",
          attrs: {
            "text": "Cancel",
            "variant": "light"
          },
          on: {
            "click": _vm.closeModal
          }
        }), _c('ba-button', {
          staticClass: "text-gray-dark",
          attrs: {
            "loading": _vm.loading,
            "disabled": _vm.$v.$invalid || _vm.loading,
            "text": `${_vm.isEdit ? 'Update' : 'Save'}`,
            "variant": "warning"
          },
          on: {
            "click": _vm.createUser
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "content"
  }, [_vm.edit ? [_c('div', [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Full name",
      "placeholder": "e.g John Doe"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.fullName.$touch();
      }
    },
    model: {
      value: _vm.form.fullName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fullName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.fullName"
    }
  }), _vm.$v.form.fullName.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Full Name is required")]) : _vm._e()], 1), _c('div', [_c('ba-datepicker', {
    staticClass: "bg-white text-black-90",
    attrs: {
      "label": "Date of Appointment",
      "value": _vm.form.dateOfAppointment,
      "required": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.dateOfAppointment.$touch();
      }
    },
    model: {
      value: _vm.form.dateOfAppointment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "dateOfAppointment", $$v);
      },
      expression: "form.dateOfAppointment"
    }
  }), _vm.$v.form.dateOfAppointment.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Date of Appointment is required")]) : _vm._e()], 1)] : [_c('div', [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "First name",
      "placeholder": "e.g John"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.firstName.$touch();
      }
    },
    model: {
      value: _vm.form.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "firstName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.firstName"
    }
  }), _vm.$v.form.firstName.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("First Name is required")]) : _vm._e()], 1), _c('div', [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Last name",
      "placeholder": "e.g Doe"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.lastName.$touch();
      }
    },
    model: {
      value: _vm.form.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lastName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.lastName"
    }
  }), _vm.$v.form.lastName.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Last Name is required")]) : _vm._e()], 1)], _c('div', [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Email address",
      "placeholder": "e.g martin@nccg.gov.ng"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.email.$touch();
      }
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _vm.$v.form.email.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Email is required")]) : _vm._e()], 1), _vm.edit ? _c('div', [_c('ba-text-field', {
    attrs: {
      "required": "",
      "label": "Alternate Email address",
      "placeholder": "e.g martin@nccg.gov.ng"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.alternate_email.$touch();
      }
    },
    model: {
      value: _vm.form.alternate_email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "alternate_email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.alternate_email"
    }
  }), _vm.$v.form.alternate_email.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Email is required")]) : _vm._e()], 1) : _vm._e(), _c('div', [_c('ba-phone-field', {
    staticClass: "text-black-90",
    attrs: {
      "label": "Phone number",
      "classText": "white",
      "required": "",
      "placeholder": "Phone number"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.phoneNumber.$touch();
      }
    },
    model: {
      value: _vm.form.phoneNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phoneNumber", $$v);
      },
      expression: "form.phoneNumber"
    }
  }), _vm.$v.form.phoneNumber.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Phone Number is required")]) : _vm._e()], 1), _c('div', [_c('ba-dropdown', {
    attrs: {
      "value": _vm.form.gender,
      "label": "Gender",
      "options": _vm.genders,
      "item-text": "text",
      "item-value": "id",
      "required": ""
    },
    model: {
      value: _vm.form.gender,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  }), _vm.$v.form.gender.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Gender is required")]) : _vm._e()], 1), !_vm.edit ? _c('div', [_c('ba-datepicker', {
    staticClass: "bg-white text-black-90",
    attrs: {
      "label": "Date of Appointment",
      "value": _vm.form.dateOfAppointment,
      "required": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.dateOfAppointment.$touch();
      }
    },
    model: {
      value: _vm.form.dateOfAppointment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "dateOfAppointment", $$v);
      },
      expression: "form.dateOfAppointment"
    }
  }), _vm.$v.form.dateOfAppointment.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Date of Appointment is required")]) : _vm._e()], 1) : _vm._e(), _c('div', [_c('ba-dropdown', {
    attrs: {
      "value": _vm.form.position,
      "label": "Position",
      "options": _vm.positionOptions,
      "required": ""
    },
    on: {
      "hide": function ($event) {
        return _vm.$v.form.position.$touch();
      }
    },
    model: {
      value: _vm.form.position,
      callback: function ($$v) {
        _vm.$set(_vm.form, "position", $$v);
      },
      expression: "form.position"
    }
  }), _vm.$v.form.position.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Position is required")]) : _vm._e()], 1), _vm.form.position === 'Others' ? _c('div', [_c('ba-text-field', {
    attrs: {
      "required": "",
      "value": _vm.form.otherPosition,
      "label": "Position(Others)",
      "placeholder": "-"
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.form.otherPosition.$touch();
      }
    },
    model: {
      value: _vm.form.otherPosition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "otherPosition", $$v);
      },
      expression: "form.otherPosition"
    }
  }), _vm.$v.form.otherPosition.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v("Position is required")]) : _vm._e()], 1) : _vm._e(), _vm.showRoles ? _c('div', [_c('ba-dropdown', {
    attrs: {
      "value": _vm.form.roles,
      "label": "Roles",
      "options": _vm.rolesArray,
      "item-text": "text",
      "item-values": "id",
      "multiple": true,
      "show-selection": true
    },
    model: {
      value: _vm.form.roles,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roles", $$v);
      },
      expression: "form.roles"
    }
  })], 1) : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }